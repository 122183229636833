import React from 'react'
import './Product.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProductCard = ({ fluid, title, priceS, priceM, priceL }) => {
  return (
    <div className="box product-box">
      <div className="card">
        <div className="card-image">
          <figure className="image">
            <GatsbyImage image={fluid} alt={'image'} />
          </figure>
        </div>
        <div className="card-content">{title}</div>
        <div className="card-footer">
          {priceS && (
            <p className="card-footer-item is-size-6-mobile">
              klein ca. &#8364; {priceS}
            </p>
          )}
          {priceM && (
            <p className="card-footer-item is-size-6-mobile">
              mittel ca. &#8364; {priceM}
            </p>
          )}
          {priceL && (
            <p className="card-footer-item is-size-6-mobile">
              gross ca. &#8364; {priceL}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
