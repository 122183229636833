import React from 'react'

const IconGardeningCat = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox="0 0 500 500"
      aria-labelledby="title"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="311.95"
          y1="222.37"
          x2="338.01"
          y2="222.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#665391" />
          <stop offset="1" stopColor="#4f4f4f" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="341.48"
          y1="219.85"
          x2="374.94"
          y2="219.85"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <title id="title"> Cat planting flowers in a garden </title>
      <path
        fill="#b5f6ff"
        d="M74.36,366.57C57.13,370.08-8.75,231.76,95.65,145.68c95.21-78.5,200.38-40.47,239.64-21,138.23,68.69,85.8,228.55,27,252.7"
      />
      <path
        fill="#fff"
        d="M335.29,124.72A259.61,259.61,0,0,0,304.76,112a17.85,17.85,0,0,0-2.19,9.1c-8.47-11.59-25,1-17.61,13-34.15-9.53-94.23,19.53,83.8,11.73A186.45,186.45,0,0,0,335.29,124.72Z"
      />
      <path
        fill="#fff"
        d="M80.18,160.07c111.69,1.35,75.71-20.84,45.36-12.37,4-6.56-2-13.62-9.77-16.82a225,225,0,0,0-20.12,14.81A174.07,174.07,0,0,0,80.18,160.07Z"
      />
      <path
        fill="url(#linear-gradient)"
        d="M338,226.13s-20.59-20.44-26.06-17.78c2.78,5.46-1.91,24.73,4.3,28.28C324.14,227.86,338,226.13,338,226.13Z"
      />
      <path
        fill="#8cc63f"
        d="M127.89,343c69.3-9.73,171.21-8,238.8,10.79s44.08,33.28.31,35.64S301,398,239.71,401.22c-54.46,2.86-135.66-5.22-147.63-8.53-18.43-5.1.83-18.31-20.08-18.31C29.12,374.38,58.58,352.69,127.89,343Z"
      />
      <path
        fill="#472c1b"
        d="M151.81,349.19c-6.71-3.36-15.66-3.52-22.85-3.36-5.86.15-22.71,2-23.27,10.43-2.44,1.91-7.64,1.46-9.17,4.27-4,7.67,28.6,7.35,31.8,7.35,7,0,14.38.48,21.41-.64,1.92-.32,5.43-1,6.07-3,.16-.8-1.12-1.6-.64-2.4.32-.64,2.24-.48,2.72-.64C166.51,358.94,155.33,350.95,151.81,349.19Z"
      />
      <path
        fill="#472c1b"
        d="M214.24,345.72c-6.71-3.36-15.66-3.52-22.85-3.36-5.86.15-22.71,2-23.27,10.43-2.44,1.91-7.64,1.46-9.17,4.27-4,7.67,28.6,7.35,31.8,7.35,7,0,14.38.48,21.41-.64,1.92-.32,5.43-1,6.07-3,.16-.8-1.12-1.6-.64-2.4.32-.64,2.24-.48,2.72-.64C228.94,355.47,217.76,347.48,214.24,345.72Z"
      />
      <path
        fill="#472c1b"
        d="M276.67,345.72c-6.71-3.36-15.66-3.52-22.85-3.36-5.86.15-22.71,2-23.27,10.43-2.44,1.91-7.64,1.46-9.17,4.27-4,7.67,28.6,7.35,31.8,7.35,7,0,14.38.48,21.41-.64,1.92-.32,5.43-1,6.07-3,.16-.8-1.12-1.6-.64-2.4.32-.64,2.24-.48,2.72-.64C291.37,355.47,280.19,347.48,276.67,345.72Z"
      />
      <path
        fill="#a7cd3d"
        d="M191.2,323c-1.72-3.29-2.25-6.09-6-7.77-2.37-1.07-8.42-2.54-9.23,1.07-1.6,7.16,12.07,9.38,15,12.77Z"
      />
      <path
        fill="#f26d28"
        d="M203,296.18c-9,7-17.24,5.81-26.08-.44-6.23-4.4-15.49-12.8-13.26-21.46,3.21-12.44,16.91-1.41,18.61,5.56-1.07-5.84-1.3-19.48,7.41-20.2,10.13-.84,9,12.79,9.41,18.65.63-4.61,4.2-14.44,10.69-12.54,5.23,1.53,5.08,10,4.1,14.11A29.36,29.36,0,0,1,203,296.18Z"
      />
      <path
        fill="#a7cd3d"
        d="M205.7,297.2c-6.41,5.67-25.86,8.55-29.63-2.08,2.47-2.09,6.86-.57,9.3,1.12-5-7.64,3.44-9.4,5.48-2.47-.05-5.78,7-8,5.62.47,1.11-2.3,3.23-4.58,5.91-4.73,1.14,1.51.45,3.44-.25,5.17C205.17,290,211,292.54,205.7,297.2Z"
      />
      <path
        fill="#a7cd3d"
        d="M190.15,300.47c.89,10.37-.25,21.8-.59,32.47a170.57,170.57,0,0,1-1.1,17.25c-.81,6,.69,4.48,5.85,5.38-.47-6.2,3.12-57.24-2.82-56.28Z"
      />
      <path
        fill="#a7cd3d"
        d="M193.95,335.18c-3-6,12.65-22.9,18.41-14.26,6.3,9.44-13.09,15.53-19.36,15.34Z"
      />
      <path
        fill="#ef5473"
        d="M270.18,292.46c-9.3,6.58-17.47,5.07-26-1.54-6-4.66-14.93-13.45-12.33-22,3.73-12.29,17-.69,18.36,6.34-.83-5.88-.47-19.52,8.26-19.87,10.16-.41,8.43,13.16,8.61,19,.83-4.58,4.81-14.25,11.22-12.08,5.16,1.75,4.65,10.24,3.5,14.27A29.36,29.36,0,0,1,270.18,292.46Z"
      />
      <path
        fill="#a7cd3d"
        d="M272.86,293.6c-6.65,5.39-26.2,7.44-29.52-3.34,2.56-2,6.88-.28,9.25,1.52-4.68-7.85,3.84-9.24,5.58-2.23.19-5.77,7.32-7.7,5.6.71,1.21-2.25,3.42-4.44,6.11-4.47,1.08,1.56.3,3.46-.47,5.15C272.64,286.36,278.33,289.17,272.86,293.6Z"
      />
      <path
        fill="#a7cd3d"
        d="M257.18,296.2c.45,10.4-1.17,21.77-2,32.42a170.58,170.58,0,0,1-1.83,17.19c-1.07,6,.5,4.51,5.61,5.62-.2-6.22,5.55-57.06-.43-56.35Z"
      />
      <path
        fill="#a7cd3d"
        d="M259.5,331c-2.7-6.12,13.61-22.35,19-13.47,5.89,9.7-13.74,15-20,14.5Z"
      />
      <path
        fill="#67c1a7"
        d="M113.75,299.09c9.66,6.64,18.09,5.17,26.88-1.4,6.19-4.63,15.3-13.38,12.54-22-4-12.33-17.52-.78-18.91,6.25.8-5.89.31-19.55-8.7-19.95-10.49-.46-8.6,13.13-8.72,19-.9-4.59-5.1-14.3-11.69-12.16-5.31,1.73-4.72,10.23-3.49,14.27A30.06,30.06,0,0,0,113.75,299.09Z"
      />
      <path
        fill="#a7cd3d"
        d="M111,300.22c6.91,5.43,27.13,7.6,30.46-3.18-2.66-2-7.11-.32-9.54,1.47,4.77-7.83-4-9.28-5.78-2.27-.25-5.78-7.63-7.75-5.78.68-1.27-2.26-3.57-4.47-6.35-4.51-1.1,1.56-.28,3.46.53,5.16C111.16,293,105.31,295.75,111,300.22Z"
      />
      <path
        fill="#a7cd3d"
        d="M127.21,302.9c-.37,10.41,1.4,21.81,2.32,32.48a166.64,166.64,0,0,0,2,17.23c1.16,6-.47,4.51-5.75,5.6.15-6.23-6.23-57.18-.05-56.43Z"
      />
      <path
        fill="#a7cd3d"
        d="M125.12,337.79c2.73-6.12-14.25-22.46-19.74-13.6-6,9.68,14.32,15.06,20.78,14.63Z"
      />
      <path
        fill="#9e9e9e"
        d="M387.43,259.08s33.82-.87,56.36,16.47c24,18.47,32.95,90.18-30.35,96.25-64.16,0-60.7-60.7-100.58-85-28.61-25.15-2.6-59.83,26.88-61.56C363.85,223.84,363.15,208.79,387.43,259.08Z"
      />
      <path
        fill="#9e9e9e"
        d="M376.16,310.24c0,1.73-3.47,52-27.75,57.23s-13.87-17.34-5.2-17.34,0-55.49,0-55.49"
      />
      <path
        fill="#9e9e9e"
        d="M365.75,306.77c0,1.73-3.47,52-27.75,57.23s-13.87-17.34-5.2-17.34,0-55.49,0-55.49"
      />
      <path
        fill="#464477"
        d="M376.16,310.24A135.11,135.11,0,0,1,373.55,330a95.18,95.18,0,0,1-6,19.07,48.52,48.52,0,0,1-5,8.73,28.92,28.92,0,0,1-7.15,7.1l-.52.37-.56.31-1.11.62c-.75.41-1.56.68-2.34,1s-1.64.47-2.45.71-1.65.33-2.48.45a27.17,27.17,0,0,1-5.07.32,11.44,11.44,0,0,1-5-1.3,6,6,0,0,1-2.93-4.34,10,10,0,0,1,.71-5.13,15.47,15.47,0,0,1,2.65-4.38,12.15,12.15,0,0,1,4-3.2,6.74,6.74,0,0,1,2.57-.66l.55,0a1.67,1.67,0,0,0,.44-.18,2.27,2.27,0,0,0,.74-.73,12,12,0,0,0,1.43-4.53,67.3,67.3,0,0,0,.54-9.88c-.05-6.63-.56-13.26-1.18-19.88s-1.37-13.23-2.28-19.81c2.1,13.13,3.87,26.34,4.23,39.68a61.92,61.92,0,0,1-.46,10,12.65,12.65,0,0,1-1.56,4.9,3.15,3.15,0,0,1-1,1,2.55,2.55,0,0,1-.71.29l-.69.07a5.84,5.84,0,0,0-2.22.59,11.25,11.25,0,0,0-3.7,3,14.54,14.54,0,0,0-2.47,4.13,9.05,9.05,0,0,0-.64,4.63,5,5,0,0,0,2.47,3.63,10.49,10.49,0,0,0,4.55,1.19,26.24,26.24,0,0,0,4.87-.29c.81-.11,1.62-.29,2.43-.43s1.57-.41,2.34-.66,1.52-.57,2.23-1l1.07-.59.53-.29.5-.35a28,28,0,0,0,7-6.85,51.2,51.2,0,0,0,5-8.54,95.21,95.21,0,0,0,6.19-18.85A134.9,134.9,0,0,0,376.16,310.24Z"
      />
      <path
        fill="#464477"
        d="M333.4,364.7a17.13,17.13,0,0,1-5.48-.2,6.6,6.6,0,0,1-4.5-3.1,7.75,7.75,0,0,1-.4-5.53,14.84,14.84,0,0,1,2.42-5,12.82,12.82,0,0,1,4.17-3.75,7.2,7.2,0,0,1,2.77-.84l.37,0,.27,0a1.31,1.31,0,0,0,.49-.18,2.35,2.35,0,0,0,.8-.82,13.19,13.19,0,0,0,1.43-5,52.27,52.27,0,0,0,.37-5.41,197.41,197.41,0,0,0-1-21.81c-.31-3.63-.68-7.26-1.08-10.89s-.82-7.25-1.25-10.88c1.29,7.19,2.3,14.43,3.1,21.7.42,3.63.7,7.28.94,10.93s.36,7.31.28,11a53.18,53.18,0,0,1-.42,5.51,22.88,22.88,0,0,1-.53,2.74,9,9,0,0,1-1.09,2.66,3.23,3.23,0,0,1-1.12,1.1,2.2,2.2,0,0,1-.79.28l-.72.06a6.33,6.33,0,0,0-2.43.72,12.77,12.77,0,0,0-4,3.45,14.15,14.15,0,0,0-2.43,4.72,7.24,7.24,0,0,0,.22,5.15,6.23,6.23,0,0,0,4.13,3.07A18.29,18.29,0,0,0,333.4,364.7Z"
      />
      <path
        fill="url(#linear-gradient-2)"
        d="M374.94,235.24s-12.65-30.79-19.59-30.79-13.87,15.61-13.87,23.41C357.08,222.66,374.94,235.24,374.94,235.24Z"
      />
      <path
        fill="none"
        stroke="#070707"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M311.13,265.15s1.62,9.36,8.67.9c7,8.45,8.67-.9,8.67-.9"
      />
      <path
        fill="#070707"
        d="M303.94,267.75a4.82,4.82,0,0,0-4.3.9,11,11,0,0,0-1.68,1.57l-.78.92c-.25.32-.51.64-.8.95a12,12,0,0,1,.38-1.2,11.81,11.81,0,0,1,.57-1.13,10.44,10.44,0,0,1,.75-1,7.2,7.2,0,0,1,1-.88,4.44,4.44,0,0,1,2.48-.92A3.35,3.35,0,0,1,303.94,267.75Z"
      />
      <path
        fill="#070707"
        d="M305.71,272.08a12,12,0,0,0-3.79,1.75,4.72,4.72,0,0,0-1.28,1.48,9.42,9.42,0,0,0-.78,2A4.05,4.05,0,0,1,301.3,273a5.3,5.3,0,0,1,2.14-1A5.11,5.11,0,0,1,305.71,272.08Z"
      />
      <path
        fill="#070707"
        d="M344.94,266.88a9.34,9.34,0,0,1,3.07,0,11.61,11.61,0,0,1,3,.91,9.56,9.56,0,0,1,2.59,1.75,7.52,7.52,0,0,1,1.78,2.52,19.74,19.74,0,0,0-2.27-1.92,15.43,15.43,0,0,0-2.51-1.44A28.77,28.77,0,0,0,344.94,266.88Z"
      />
      <path
        fill="#070707"
        d="M341.48,272.08a7.26,7.26,0,0,1,3.05.2,8,8,0,0,1,2.79,1.39,7,7,0,0,1,2,2.4,7.17,7.17,0,0,1,.82,2.95,17.84,17.84,0,0,0-1.48-2.54,9.14,9.14,0,0,0-2-2A13.74,13.74,0,0,0,341.48,272.08Z"
      />
      <path
        fill="#9e9e9e"
        d="M389.9,351.69l-2.47,10.57S377,364,378.76,369.2s27.75,1.73,27.75,1.73"
      />
      <path
        fill="#464477"
        d="M389.9,351.69c-.32,1.78-.69,3.56-1,5.33s-.74,3.54-1.11,5.32l0,.21-.23,0a23.54,23.54,0,0,0-4.79,1.54c-1.47.69-3,1.63-3.52,3a2.86,2.86,0,0,0-.13,1.07,2.58,2.58,0,0,0,.29,1,3.33,3.33,0,0,0,1.8,1.23,19.66,19.66,0,0,0,4.9.94,94.49,94.49,0,0,0,10.21.1q5.12-.21,10.25-.62-5.07.88-10.21,1.27a68,68,0,0,1-10.32.16,20.5,20.5,0,0,1-5.17-.93,4.16,4.16,0,0,1-2.3-1.68,3.63,3.63,0,0,1-.19-2.88,5,5,0,0,1,1.73-2.19,11.33,11.33,0,0,1,2.31-1.33,21.4,21.4,0,0,1,5-1.42l-.27.26c.46-1.75.88-3.51,1.36-5.26S389.4,353.43,389.9,351.69Z"
      />
      <path
        fill="#464477"
        d="M414.68,371.58a43.47,43.47,0,0,0,24.34-7c18.27-12,22.7-34.9,22.88-35.88A6.07,6.07,0,0,0,450,326.46c-.06.32-3.8,18.94-17.67,28-7.53,4.91-16.91,6.19-27.92,3.82-5.92-3.44-12.2-9-10.32-16.29,1.35-5.27,6-8.92,8.11-9.42a6.07,6.07,0,0,0,7.76-9.27c-3-3-7.53-3.75-12.33-2-7.14,2.64-15.16,11.12-16,21.62-.45,5.95,1.23,17.28,17.59,26.37l.75.42.83.19A61.72,61.72,0,0,0,414.68,371.58Z"
      />
      <path
        fill="#070707"
        d="M320.11,265.54l4.41-3.89a.48.48,0,0,0-.32-.83h-8.81a.48.48,0,0,0-.32.83l4.41,3.89A.48.48,0,0,0,320.11,265.54Z"
      />
      <path
        fill="#070707"
        d="M315.46,254.89a5.16,5.16,0,0,1-.54,2.31,5.2,5.2,0,0,0-9.32,0,5.2,5.2,0,1,1,9.86-2.31Z"
      />
      <path
        fill="#070707"
        d="M336.27,254.89a5.16,5.16,0,0,1-.54,2.31,5.2,5.2,0,0,0-9.32,0,5.2,5.2,0,1,1,9.86-2.31Z"
      />
    </svg>
  )
}

export default IconGardeningCat
